@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/fonts.scss";

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.select-wrapper::after {
  content: url(../../../assets/icons/dropDown.svg);
  position: absolute;
  right: 2rem;
  top: 0.65rem;
}

.dropdown-menu {
  box-shadow: 0px 0px 11px $box-shadow-grey-clr;
  border: 1px solid $dim-gray-border-clr-2;
}

.dropdown-item:hover {
  // background: $light-grey-clr 0% 0% no-repeat padding-box;
  background: $blue-hover 0% 0% no-repeat padding-box;
}

.Dropdown_dropdown__2JICB
  .Dropdown_button__24tY9.Dropdown_button-secondary__S0fsL {
  background: none;
  color: none;
}

.Dropdown_dropdown__2JICB
  .Dropdown_button__24tY9.Dropdown_button-secondary__S0fsL:hover,
.Dropdown_dropdown__2JICB
  .Dropdown_button__24tY9.Dropdown_button-secondary__S0fsL.Dropdown_active__3AkUr {
  background: none;
}

.Dropdown_dropdown__2JICB
  .Dropdown_button__24tY9.Dropdown_button-secondary__S0fsL:focus {
  box-shadow: none;
}

.Item_item__34h1P:hover {
  background: $ghost-white-hover-clr 0% 0% no-repeat padding-box;
  color: $pure-black-clr;
}

.dropDown-sub-item {
  color: $gondola-grey-clr;
  font: $f-900-1723-av;
  padding: 0;
  text-align: left;
  width: 100%;
}
.Submenu_submenu__1PCnm {
  width: fit-content;
}

.Submenu_right__3fJhN {
  left: 100%;
}

.w-45px {
  width: 45px;
}

.w-50px {
  width: 50px;
}

.disabled-item {
  cursor: default;
}

.Dropdown_button__24tY9.Dropdown_button-secondary__S0fsL:disabled {
  padding: 0;
}

.list-font {
  font: $f-nm-16-po;
}
