@import "../../assets/scss/colors.scss";
@import "../../assets//scss/fonts.scss";

.home-bots-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1.5rem 3rem;
}

.home-main-content-container {
  height: 100%;
  overflow-y: scroll;
}

.home-main-content-container::-webkit-scrollbar {
  display: none;
}

.home-opts-dropdown:after {
  border-bottom-color: $white-clr;
  border-top: 0;
  border: 7px solid transparent;
  content: "";
  left: 15%;
  margin-left: -7px;
  margin-top: -7px;
  position: absolute;
  top: 0;
}

.home-right-content-container {
  height: 100%;
  max-width: 30rem;
  overflow: auto;
  padding: 1% 2% 0;
}

.no-bots-avail-title {
  color: $grey-clr;
  font: $f-900-2520-av;
  height: 34px;
  width: 491px;
}

.no-bots-avail-title-2 {
  color: $black-clr-2;
  font: $f-900-2227-av;
}

.no-bots-avail-steps {
  color: $black-clr-2;
  font: $f-900-1734-av;
  margin-bottom: 0;
}

.home-know-more-panel-container {
  background: $bg-white-clr-2 0% 0% no-repeat padding-box;
  border: 1px solid $dim-gray-grey-clr-2;
}

.home-bot-list-container {
  background: $bg-smoke-clr 0% 0% no-repeat padding-box;
  height: 100%;
  margin-top: 0.25rem;
  overflow: auto;
  width: 100%;
}
