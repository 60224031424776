@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.intents-btn {
  background: $east-bay-blue-clr 0% 0% no-repeat padding-box;
  border-radius: 3px 0px 0px 3px;
  border: 1px solid $ultramarine-clr;
  box-shadow: 0px 0px 2px $black-clr;
  color: $white-clr;
  width: 100%;
}

.train-btn {
  background: #495072 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 2px #00000029;
  border: 1px solid #51597e;
  border-radius: 3px;
  color: #fff !important;
}

.train-info-btn {
  border-color: $ultramarine-clr !important;
  color: $ultramarine-clr;
  padding-top: 0.15rem;
  padding-bottom: 0.23rem;
}

.intent-font-train {
  font: $f-900-1620-av;
}

.intent-font-save {
  font: $f-900-1620-av;
}

// .intents-btn:hover {
//   color: $white-clr;
// }

.intents-dropDown {
  background: $ultramarine-clr 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 14.5rem;
}

.intents-dropDown-text {
  color: $white-clr;
  font: $f-900-1519-av;
}

.intents-dropDown:hover,
.intents-dropDown:focus {
  background: $ultramarine-clr !important;
}

.intents-play-btn {
  background: $white-clr 0% 0% no-repeat padding-box;
  border: 1px solid $ultramarine-clr;
  border-radius: 0px 3px 3px 0px;
  padding: 0.2rem 0.4rem;
  font: $f-nm-16-po;
}

.intents-last-update-text {
  color: $charcoal-grey-clr;
  font: $f-900-1620-av;
}

.nav-gnani-logo {
  width: 4.5rem;
}

.nav-user-bg {
  background: $wild-blue-yonder-blue-clr;
}

.nav-links {
  color: $black-pearl-blue-clr;
  font-size: 1.1rem;
}

.nav-link-active {
  font: $f-900-1819-av;
}
.nav-link-font {
  font: $f-nm-1819-av;
}

.user-name-font {
  font: $f-900-1620-av;
}

.user-email-font {
  font: $f-md-1523-po;
  color: #7b7b7b;
}
.logout-font {
  font: $f-md-16-po;
}

.nav-links:hover {
  color: $black-pearl-blue-clr;
}

.navbar-profile-icon-container {
  background: $wild-blue-yonder-blue-clr;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.navbar-main-container {
  // box-shadow: 0px 3px 6px #0000000b;
  align-items: center;
  background: $white-clr 0% 0% no-repeat padding-box;
  display: flex;
  height: 3.2rem;
}

.highlight:hover {
  background: #ebe9e8 0% 0% no-repeat padding-box;
}

.training-info-container {
  max-width: 264px !important;
  width: 264px !important;
  // height: 438px !important;
  background: #5d5c66;
  padding: 0.25rem 0rem !important;
}

.training-info-arrow::before {
  border-bottom-color: #5d5c66 !important;
}
.tooltip.show {
  opacity: 1;
}

.training-info-subheader {
  height: 30px;
  color: #bebdbf;
  font: $f-md-16-po;
  background: #66656e 0% 0% no-repeat padding-box;
}

.training-status {
  font: $f-sbld-20-po !important;
}
.training-key {
  font: $f-md-16-po !important;
  color: #8b8a99;
  text-align: left;
  padding-left: 1rem;
}
.training-value {
  font: $f-md-16-po !important;
  text-align: left;
  color: #fff;
}

.truncate {
  width: 11.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.env-drop-down-btn {
  width: 162px;
  height: 33px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 2px #00000029;
  border: 1px solid #51597E;
  border-radius: 3px;
  opacity: 1;
}

.deploy-comp-paswrd-input {
  width: 441px;
  height: 48px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #181818;
  border-radius: 4px;
  opacity: 1;
}

.arrow-icon-clr {
  color: #51597E;
}

.deploy-cancel-btn {
  width: 131px;
  height: 47px;
  font: $f-md-1523-po;
  border: 1px solid #505981;
  color: #505981;
  border-radius: 2px;
  opacity: 1;
}
.deploy-confirm-btn {
  width: 141px;
  height: 47px;
  font: $f-md-1523-po;
  background: #505981 0% 0% no-repeat padding-box;
  color: #fff;
  border-radius: 2px;
  opacity: 1;
}
.deploy-info-1 {
  text-align: center;
  font: $f-nm-1625-po;
  color: #8F8F8F;
}
.deploy-info-2 {
  text-align: center;
  font: $f-nm-1625-po;
  color: #181818;
}
.deploy-info-3 {
  text-align: center;
  font: $f-nm-1218-po;
  color: #8F8F8F;
}
