@import "../../assets/scss/colors.scss";
@import "../../assets//scss/fonts.scss";

.bot {
  align-items: center;
  border-radius: 9px;
  // box-shadow: 2px 3px 6px $dark-black-clr;
  cursor: pointer;
  display: flex;
  height: 100px;
  justify-content: center;
  margin: 0 auto 12px;
  width: 100px;
  &:hover {
    box-shadow: 0px 0px -8px #0000005c;
    filter: brightness(90%);
  }
}

.bot-container {
  margin: 2rem 2rem;
  padding: 0;
  text-align: center;
  width: 6rem;
}

.bot > img {
  width: 100%;
  height: 100%;
  border-radius: 9px;
}

.bot-name {
  color: $pure-black-clr;
  font-size: 16px;
  font-weight: bold;
  font: $f-md-1619-av;
  letter-spacing: 0.5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bot-know-more {
  color: $denim-clr;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font: $f-md-1519-av;
  margin: 0;
}
