@import "../../assets/scss/fonts.scss";

.active-widget-icon {
  color: #51597e;
  border: 2px solid #51597e;
}
.title-font {
  font: $f-sbld-25-po;
}

.web-sdk-background {
  background: #f5f5f5;
  height: 95vh;
}

.web-sdk-shape {
  color: #51597e;
}

h3 {
  font: $f-md-2039-po !important;
}

.customization-heading {
  font: $f-sbld-20-po;
}

.theme-color-selector {
  background-color: #25293d;
}

.chat-window-body {
  background-color: #eeeeee;
  min-height: 40vh;
}

.chat-window-footer {
  min-height: 8vh;
  border-top: 1px solid rgb(225, 218, 218);
  background-color: #eeeeee;
}

.chat-window-title {
  font: $f-md-2531-po;
}

.chat-window-description {
  font: $f-md-1726-po;
}

.shape-selector {
  border: 5px solid black !important;
}

.chat-window-border1 {
  border: 3px solid rgb(37, 41, 61);
  border-radius: 2px;
}

.chat-window {
  width: 500px;
}

.selected-color {
  font: $f-md-1719-po;
}

.key-font {
  font: $f-md-1719-po;
}
.btns-font {
  font: $f-md-1520-po;
}
