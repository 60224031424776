@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.url-link-text {
  font: $f-sbld-1623-po;
}

.submit-button {
  color: #ffffff;
  background-color: #51597e;
  font: $f-900-1520-av;
  border-radius: 8px;
  &:hover {
    background-color: #404970;
  }
}
.modal-btn-confirm {
  background-color: #505981;
  font: $f-900-1522-av;
  &:hover {
    background-color: #404970;
  }
}
.modal-btn-cancel {
  background-color: #ffffff;
  border: 1px solid #505981;
  font: $f-900-1522-av;
  color: #505981;
}
.website-url {
  color: #000;
  background-color: none;
  font: $f-sbld-1720-po;
  // border-radius: 8px;
  // border: 1px solid #000;
}

.url-link-input {
  background: $white-clr 0% 0% no-repeat padding-box;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  width: 100%;
  font: $f-nm-1625-po;
}

.depth-dropdown {
  background: $white-clr 0% 0% no-repeat padding-box;
  width: 162px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  color: #8f8f8f;
  padding: 8px 16px;
}
.depth-dropdown-text {
  font: $f-nm-14-po;
}
.recrawl-depth-dropdown {
  background: $white-clr 0% 0% no-repeat padding-box;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  color: #6f6f6f;
  padding: 6px 16px;
}
.badge-style {
  font: $f-md-1220-po !important;
  color: #51597e;
  background-color: #f2f4ff;
}

.table td {
  padding: 24px 12px;
}

.table th {
  padding: 12px 12px;
}

.completed-text {
  font: $f-md-1220-po;
  color: #027a48;
  background-color: #ecfdf3;
}

.in-progress-text {
  font: $f-md-1220-po;
  color: #f97c22;
  background-color: #fef3eb;
}

.text-14 {
  font: $f-md-1420-po;
  color: #0d2981;
}
.text-enabled-14 {
  font: $f-md-1420-po;
  color: #0d2981;
}
.text-disabled-14 {
  font: $f-md-1420-po;
  color: rgba(13, 41, 129, 0.4);
}

.text-16 {
  font: $f-md-1620-po;
  color: rgba(0, 0, 0, 0.75);
}

.date-text {
  font: $f-nm-1420-av;
  color: rgba(0, 0, 0, 0.75);
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: #f2f4ff;
}

.url-details-text {
  font: $f-600-2030-po;
}
.url-badge-style {
  background-color: #dbf0ff;
  border-radius: 14px;
  font: $f-900-1620-av;
  color: rgba(0, 0, 0, 0.75);
}
.statistics-text {
  font: $f-900-1723-av;
}
.statistics-label-text {
  font: $f-900-1622-av;
  color: #7b7b7b;
}
.stats-left-panel {
  background-color: $white-clr;
}

.stats-left-panel-header-inp {
  background: transparent;
  font: $f-nm-1625-po;
  border: none;
  width: 15rem;
}

.stats-left-panel-header-inp::placeholder {
  color: #b9b9b9;
  font: $f-md-1624-po;
}

.selected-chatList {
  background: #f2f2f2 !important;
}

.chat-list-con-id {
  font: $f-900-1723-av;
  color: #414141;
}

.call-stats-pg-value {
  color: rgba(144, 144, 144, 0.67);
  font: $f-md-1420-po;
}
.disabled-dropdown-btn {
  opacity: 0.5;
  cursor: default !important;
}
.stats-right-panel-title {
  font: $f-900-2531-av;
  color: #868686;
}
.stats-right-panel-val {
  color: rgba(0, 0, 0, 0.75);
  font: $f-sbld-2436-po;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.stat-chat-history {
  align-items: center;
  border-radius: 14px;
  color: $black-clr-2;
  // display: flex;
  font: $f-900-1723-av;
  justify-content: center;
  margin: 0.5rem 0;
  max-width: 60%;
  min-width: 5rem;
  padding: 0.5rem 1rem;
}
.bot-reply {
  background: #ffdbdb 0% 0% no-repeat padding-box;
  margin: 0.5rem 0 0;
}

.user-reply {
  background: #dbf0ff 0% 0% no-repeat padding-box;
  margin: 0.5rem 0 0;
}
.link-bot-dropdown {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  color: #707070;
  font: $f-nm-1520-av;
}
.link-modal-search {
  border: none;
}
.modal-heading-text {
  color: #414141;
  font: $f-sbld-1720-po;
}
.bot-name-text {
  font: $f-md-1624-po;
  color: #000000;
}
.bot-id-text {
  font: $f-nm-1420-av;
  color: #707070;
}
.table-container {
  height: calc(100% - 6.5rem);
  width: 100%;
  overflow: auto;
}
.table-container table {
  width: 100%;
  border-collapse: collapse;
}
.table-container thead {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  font: $f-md-1419-po;
  color: rgba(0, 0, 0, 0.75);
  z-index: 99;
}
