.form-input {
  width: 25rem;
  height: 2.7rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #70707026;
}

.form-input::placeholder {
  color: #707070;
  opacity: 0.47;
}

.submit-btn {
  background: #51597e 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #ffffff;
}

.cancel-btn {
    border: 1px solid #505981;
    border-radius: 2px;
    color: #505981;
  }
