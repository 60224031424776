@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";


.dropdown-text {
  width: 15rem;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
}

.form-text {
  font: $f-md-1720-po !important;
  color: #000000be;
}

.form-text2 {
  font: $f-md-16-po;
  color: #495072;
}
.form-text3 {
  font: $f-nm-1723-av;
  color: #495072;
}
.form-text4 {
  font: $f-nm-1520-av;
  color: #000000be;
}
.form-text5 {
  font: $f-md-1723-po;
  color: #5d5d5d;
}
.form-condition-text {
  font: $f-900-2831-av;
}

.slot-DropDown {
  min-width: 11rem;
  height: 2.75rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 6px #0000000d;
  border: 1px solid #f1f1f1;
  border-radius: 3px;
}

.response-DropDown {
  min-width: 22.5rem;
  height: 44px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 6px #0000000d;
  border: 1px solid #f1f1f1;
}

.isIntentOneof-DropDown {
  width: 14.5rem;
  height: 2.75rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 6px #0000000d;
  border: 1px solid #f1f1f1;
  border-radius: 3px;
  color: #000;
}

.forms-input {
  width: 29.5rem;
  height: 2.75rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 6px #0000000d;
  border: 1px solid #f1f1f1;
  border-radius: 3px;
  font: $f-nm-16-po;

}
.forms-input::placeholder {
  color: #bcbcbc;
}

.multi-select {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 6px #0000000d;
  border: 1px solid #f1f1f1;
  border-radius: 3px;
  width: 27rem !important;
  //height: 2.75rem !important;
  min-height: 2.75rem !important;
}
.multi-select-options {
  //display: flex;
  margin: 3px;
  cursor: pointer;
  //flex-direction: row;
}

.multi-select-options > div {
  background: #f0f0f0 0% 0% no-repeat padding-box;
  border-radius: 2px;
  color: #5d5d5d;
}
.react-dropdown-select-content ::placeholder {
  color: #bcbcbc !important;
  font-size: 1rem;
}

.react-dropdown-select-dropdown-handle > svg {
  width: 1.8rem !important;
  height: 1.8rem !important;
}

.condition-btn {
  border: 1px solid #6c7acc;
  border-radius: 5px;
  color: #6c7acc;
  font: $f-md-15-po;
}

.selected-btn {
  background: transparent linear-gradient(297deg, #7983b4 0%, #5468f7 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  color: white;
  font: $f-md-15-po;
}
