@import "../../assets/scss/colors.scss";
@import "../../assets//scss/fonts.scss";

.header-container {
  font: $f-md-2531-po;
}

.name-font {
  font: $f-md-1719-po;
}
.organisation-list {
  color: #767676;
  font: $f-md-1719-po;
}
.user-input {
  &::placeholder {
    color: #707070;
    opacity: 0.47;
    font: $f-md-1719-po;
  }
  width: 350px;
  height: 42px;
  font: $f-nm-1726-po;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #70707026;
  opacity: 1;
}

.organisation-table-th {
  height: 48px;
  color: white;
  text-align: center;
  font: $f-600-1523-po;
  background: #737da8 0% 0% no-repeat padding-box;
  opacity: 0.91;
  border: 1px dashed #c2c2c2;
}

.org-table-data-row {
  cursor: pointer;
  height: 99px;
  &:hover {
    background: $ghost-white-hover-clr;
  }
}
.organisation-table-data {
  vertical-align: middle;
  text-align: center;
  border: 1px dashed #c2c2c2;
}
.selected-org {
  background: #f5f5f5 0% 0% no-repeat padding-box !important;
}
.seleceted-org-clr {
  font: $f-nm-1625-po;
  color: #454545 !important;
}
.login-btn-cancel {
  width: 133px;
  height: 44px;
  // background: transparent url('img/Rectangle 1732.png') 0% 0% no-repeat padding-box;
  border: 1px solid #465699;
  color: #465699;
  border-radius: 2px;
  font: $f-md-1719-po;
}

.super-login-btn-confirm {
  width: 124px;
  height: 44px;
  // background: transparent url('img/Rectangle 2216.png') 0% 0% no-repeat padding-box;
  background: #465699;
  color: white;
  border-radius: 4px;
  opacity: 1;
  font: $f-md-1719-po;
}

.admin-edit-btn {
  width: 107px;
  height: 48px;
  font: $f-md-1726-po;
  // background: transparent url("img/Rectangle 2216.png") 0% 0% no-repeat padding-box;
  border: 1px solid #2c355c;
  border-radius: 4px;
  opacity: 1;
}
.deactive-btn-row {
  opacity: 0.1;
}

.active-btn-container {
  width: 246px;
  height: 48px;
  border: 1px solid #2c355c;
  border-radius: 8px;
  opacity: 1;
}
.active-btn-font {
  font: $f-md-1726-po;
}
.active-background {
  background: #2c355c 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 0px #0000000b;
  border-radius: 8px;
}

.super-admin-title {
  font: $f-900-2531-av;
}

.super-admin-search-bar {
  &::placeholder {
    color: #707070;
    opacity: 0.47;
  }
  width: 260px;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  opacity: 1;
}

.new-org-btn {
  width: 260px;
  height: 48px;
  color: white;
  background: #2c355c;
  font: $f-md-1726-po;
  border-radius: 4px;
  opacity: 1;
}

.super-admin-reset-btn {
  width: 212px;
  height: 48px;
  color: #2c355c;
  font: $f-md-1726-po;
  border: 1px solid #2c355c;
  border-radius: 4px;
}

.super-admin-delete-btn {
  width: 128px;
  height: 48px;
  color: #2c355c;
  font: $f-md-1726-po;
  border: 1px solid #2c355c;
  border-radius: 4px;
}

.search-icon {
  position: relative;
  left: 30px;
  font-size: larger;
  color: #707070;
  opacity: 0.47;
}

.modal-heading {
  color: #959595;
  font: $f-md-1726-po;
}

.org-modal-label {
  font: $f-md-1726-po;
  color: #222222;
  padding: 0;
}

.org-modal-inp {
  &::placeholder {
    color: #959595;
  }
  width: 280px;
  height: 44px;
  font: $f-nm-1726-po;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #0000001d;
  border-radius: 2px;
  opacity: 1;
}

.org-modal-cancel-btn {
  width: 131px;
  height: 47px;
  font: $f-md-1523-po;
  color: #505981;
  border: 1px solid #505981;
  border-radius: 2px;
}

.org-modal-add-btn {
  width: 220px;
  height: 47px;
  color: white;
  font: $f-md-1523-po;
  background: #505981 0% 0% no-repeat padding-box;
  border-radius: 2px;
}

.org-password-confirm-btn {
  width: 141px;
  height: 47px;
  color: white;
  font: $f-md-1523-po;
  background: #505981 0% 0% no-repeat padding-box;
  border-radius: 2px;
}

.org-table-detail-font {
  font: $f-nm-1625-po;
  color: #767676;
}

.org-edit-inp{
  width: 160px;
  border: none;
  font: $f-nm-1625-po;
}

.org-lo-btn{
  height: 31px;
  width: 31px;
  border-radius: 50%;
}
