@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.stats-drop-down {
  background: $ultramarine-clr 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: $white-clr;
  cursor: default;
  font: $f-900-1727-av;
  padding: 0.5rem;
}

.stats-drop-down:hover {
  color: $white-clr;
}

.stats-info-container {
  background: $white-clr;
  padding: 0.5rem 1.25rem;
  width: 17rem;
  margin-top: 0.5rem;
  border-radius: 0.25rem;
}

.stats-pg-title {
  color: $black-clr-2;
  display: block;
  font: $f-900-2531-av;
}

.stats-info-container-title {
  color: $black-clr-2;
  font: $f-900-2531-av;
}

.stats-pg-sub-title {
  color: $santas-grey-blue-clr;
  font: $f-nm-1531-av;
}

.stat-pg-select-date {
  color: $grey-clr-2;
  font: $f-md-1722-av;
  margin-bottom: 0.25rem;
}

.stats-info-sub-value {
  color: $santas-grey-blue-clr;
  font: $f-900-1131-av;
  margin-left: 0.25rem;
}

.progress {
  height: 6px;
  border-radius: 10px;
}

.progress-bar {
  background-color: $cornflower-blue-clr;
}

.stats-con-split-chart-value {
  color: $nero-grey-clr;
  font: $f-900-2231-av;
  margin-bottom: 0;
}

.stats-con-split-chart-bar-title,
.stats-con-split-chart-per-value {
  color: $nero-grey-clr;
  font: $f-900-1731-av;
  margin-bottom: 0;
}

.stats-con-split-chart-title {
  color: $grey-clr-2;
  font: $f-900-1531-av;
}

.stats-con-split-chart-per-value {
  padding-left: 0;
  text-align: right;
  width: 4.5rem;
}

.call-stats-left-panel {
  background-color: $white-clr;
  height: 100%;
  padding: 0.5rem 1rem;
}

.call-stats-left-panel-header-inp-cont {
  align-items: center;
  background: $white-smoke-white-clr 0% 0% no-repeat padding-box;
  display: flex;
  padding: 0;
  padding-left: 0.15rem;
  margin-left: 0.5rem;
}

.call-stats-left-panel-header-inp {
  background: transparent;
  border-radius: 4px;
  border: none;
  height: 41px;
  width: 9.5rem;
}

.call-stats-left-panel-header-inp::placeholder {
  color: $secondary-dim-gray;
  font: $f-nm-1723-av;
  opacity: 0.47;
}

.call-stats-all-sel-sec-label {
  color: $mine-shaft-blue-clr;
  font: $f-900-1831-av;
}

.call-stats-pg-value {
  color: $suva-grey-clr-2;
  font: $f-md-12-po;
  margin: 0;
}

.chat-stats-chat-data-container {
  margin-bottom: 0.25rem;
  padding: 0.5rem 0;
}

.chat-stats-right-panel-title {
  font: $f-900-2531-av;
  color: $grey-clr;
}

.chat-stats-right-panel-val {
  color: $black-clr-2;
  font: $f-900-2531-av;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-stats-history-type-cont {
  background: transparent 0% 0% no-repeat padding-box;
  border-radius: 14px;
  border: 1px solid #4949493b;
  width: 132px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-con-details-heading {
  font: $f-md-1719-av;
  color: $pure-black-clr;
}

.chat-stats-chat-details {
  background: $white-clr 0% 0% no-repeat padding-box;
  border-radius: 13px;
  box-shadow: 0px 1px 0px $box-shadow-black-clr;
  max-height: 390px;
  margin-right: 1.65rem;
  padding: 1rem 1rem 3rem;
  overflow: auto;
}

.chat-stat-chat-history {
  align-items: center;
  border-radius: 14px;
  color: $black-clr-2;
  // display: flex;
  font: $f-900-1731-av;
  justify-content: center;
  margin: 0.5rem 0;
  max-width: 60%;
  min-width: 5rem;
  padding: 0.5rem 1rem;
}

.chat-bot-reply {
  background: #ffdbdb 0% 0% no-repeat padding-box;
}

.chat-user-reply {
  background: #dbf0ff 0% 0% no-repeat padding-box;
  margin: 0.5rem 0 0;
}

.selected-chatList {
  background: $bg-white-clr-2;
}

.overAll-stat-left-isHeader,
.overAll-stat-left-nonHeader,
.overAll-stat-left-chart-name {
  font: $f-900-1731-av;
}

.overAll-stat-left-isHeader {
  color: $grey-clr-2;
}

.overAll-stat-left-nonHeader,
.overAll-stat-left-val {
  color: $nero-grey-clr;
}

.overAll-stat-left-val {
  font: $f-900-1731-av;
}

.overAll-stat-left-chart-name {
  color: $eclipse-grey-clr;
  display: block;
  text-align: center;
}

.chat-list-con-id {
  color: $charcoal-grey-clr;
  cursor: pointer;
  font: $f-900-1631-av;
  margin: 0;
}

.debug-info {
  color: $dodge-blue-clr;
  cursor: pointer;
  font: $f-900-1231-av;
  margin: 0;
}

.accordion-button {
  padding: 0;
}

.accordion-button::after {
  display: none;
}

.accordion-button:focus {
  border: none;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
}

.chat-stat-user-info,
.chat-stats-history-type {
  color: $black-clr-2;
}

.chat-stat-user-info {
  font: $f-900-1620-av;
}

.chat-stats-history-type {
  font: $f-md-1619-av;
}

.chat-con-details-key {
  color: $grey-clr-2;
  font: $f-900-1622-av;
  margin: 0.5rem;
}

.chat-con-details-value {
  color: $pure-black-clr;
  font: $f-900-1723-av;
}

.chat-stat-date {
  color: $white-clr;
  font: $f-900-1727-av;
  cursor: default;
}

.stats-multi-level-drop-down-item {
  color: $black-pearl-blue-clr;
  font: $f-900-1522-av;
}

.disabled-dropdown-btn {
  opacity: 0.5;
  cursor: default !important;
}

.daterangepicker.ltr.show-calendar.opensright {
  width: 490px !important;
  right: 0px !important;
  left: auto !important;
}
