@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.modal-backdrop {
    background: $ultramarine-clr !important;
    backdrop-filter: blur(10rem) !important;
}

.modal-component {
    .modal-content {
        margin: auto;
        // background: $midnight-express-blue-clr;
        background: #FFF;
        border-radius: 1rem;
        .modal-header {
            .btn-close {
                color: $midnight-express-blue-clr!important;
            }
        }
    }
    display: flex;
    height: 100%;
    margin: auto;
}

.title-font {
    font: $f-sbld-20-po;
}