@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";
// @import "../../../node_modules/bootstrap/scss/bootstrap.scss";

*::-webkit-scrollbar {
  display: none;
}

.bot-menu,
.integration-menu {
  cursor: pointer;
  font: $f-nm-16-po;
}

.integration-menu {
  padding: 1rem;
  padding-left: 0.5rem;
}
.button {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.Dropdown_menu__2APrD.Dropdown_menu-right__1zrzt {
  ul {
    margin: 0.3rem 0 !important;
  }
  box-shadow: 0px 3px 6px #00000029 !important;
}

.Submenu_submenu__1PCnm.Submenu_right__3fJhN.my-1.submenu {
  box-shadow: 0px 3px 6px #00000029 !important;
}

.multi-level-dropdown-btn {
  height: auto !important;
}

.submenu {
  ul {
    margin: 0 0 !important;
    li {
      align-items: center !important;
    }
  }
}

.bot-menu:hover,
.integration-menu:hover,
.bot-layout-selected-item {
  background: $ghost-white-clr 0% 0% no-repeat padding-box;
  font: $f-md-16-po !important;
}

.greeting-msg-container {
  background: $white-smoke-white-clr 0% 0% no-repeat padding-box;
  padding: 0.5rem !important;
  margin: 0.5rem 0 !important;
}

.greeting-msg {
  font: $f-900-1720-av;
  font-weight: bold;
  color: $black-clr-2;
}

.bot-layout-inp {
  background: $white-clr 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 6px $black-box-shadow-clr;
  border: 1px solid $dim-gray-grey-clr;
  border-radius: 3px;
  padding: 0.5rem 1rem;
  width: 100%;
  font: $f-nm-16-po;
}

.bot-layout-inp::before {
  content: "EN";
  border: 1px solid #f30;
}

.bot-layout-inp::placeholder {
  color: $secondary-dim-gray;
  font: $f-nm-16-po;
  opacity: 0.47;
}

.bot-layout-greetings-inp {
  background: transparent;
  border: none;
  width: 100%;
  font: $f-900-1620-av;
  color: $black-clr-2;
  height: 100%;
}

.bot-content-title-forms {
  background: transparent;
  border: none;
  // font-weight: bold;
  // font-size: 20px;
  font: $f-sbld-20-po;
  padding: 0.5rem 0.75rem;
  width: 25rem;
}

.bot-content-title {
  background: transparent;
  border: none;
  // font-weight: bold;
  // font-size: 20px;
  font: $f-sbld-20-po;
  padding: 0.5rem 0.75rem;
  width: 25rem;
}

.bot-content-title:focus {
  background: $white-smoke-white-clr-2;
}

.bot-layout-sidebar-text {
  color: $black-clr-2;
  // font: $f-nm-16-po;
  white-space: nowrap;
}

.bot-layout-settings-pg-left-panel {
  cursor: pointer;
  padding: 1rem 1.5rem;
  font: $f-nm-16-po;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 7rem;
}

.name-trim {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  // width: 85%;
}

.integration-sidebar-title {
  color: $charcoal-grey-clr-2;
  font: $f-900-1620-av;
  margin: 0;
  padding-left: 1rem;
}

.integration-dropdown-item {
  font: $f-900-1522-av;
  color: $black-pearl-blue-clr;
}

.integration-pg-side-bar-name {
  color: $gondola-grey-clr;
  font: $f-900-1723-av;
}

.bot-left-main-title {
  color: $black-clr-2;
  font: $f-900-1620-av;
}

.caret-icon::after {
  content: none !important;
}

.intent-library-industry {
  width: 11rem;
  height: 2.75rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 6px #0000000d;
  border: 1px solid #f1f1f1;
  border-radius: 3px;
  color: #000;
}
.library-search {
  position: relative;
  right: 30px;
  font-size: larger;
  color: #707070;
  opacity: 0.47;
  width: 1px;
}
.library-accordian-cont {
  height: 28rem;
  width: 100%;
}

.flow-path-header {
  font: $f-900-1723-av;
}
.forms-menu-text {
  color: #000000be;
  white-space: nowrap;
  font: $f-nm-16-po;
}
.selected-form-menu {
  background: $ghost-white-clr 0% 0% no-repeat padding-box;
  font: $f-nm-16-po;
}

.faq-info-font {
  font: $f-600-1726-po;
}
.faq-warning-font {
  font: $f-nm-14-po;
}

.train-log-cont {
  border-radius: 10px;
  background: #444858;
}

.train-log-nav {
  height: 48px;
  background: #495072;
  color: white;
  border-top-left-radius:   10px;
  border-top-right-radius:   10px;
  font: $f-md-1726-Po;
}
.train-label-font {
  color: red;
  font: $f-nm-14-po;
}
.train-value-font {
  color: white;
  font: $f-nm-14-po;
}

.train-info-font {
  color: #0066FF;
  font: $f-nm-14-po;
}

.bulk-import-sub-heading {
  font: $f-md-1620-po;
  color: #525A7E;
} 

.confirm-btn-no-width {
  height: 40px;
  color: #ffffff;
  background: #525A7E;
  border-radius: 4px;
  font: $f-md-1420-po;
}
.no-border-cancel-btn {
  height: 40px;
  font: $f-md-1420-po;
  color: #525A7E;
}
.import-sub-header {
  font: $f-md-1220-po;
  color: #8F8F8F;
}
.modal-err-font {
  font: $f-nm-1015-po;
  color: #D60000;
}
.imp-warning-font {
  font: $f-md-1018-po;
  text-align: center;
  color: #000;
}
.imp-validation-msg {
  font: $f-md-1620-po;
  color: #000000;
}