@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

#diamond {
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-bottom-color: #227ff7 !important;
  position: relative;
  top: -52px;
}
#diamond:after {
  content: "";
  position: absolute;
  left: -50px;
  top: 50px;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-top-color: #227ff7 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.cur-pointer {
  cursor: pointer;
}

.outline-0 {
  outline: none !important;
  box-shadow: none !important;
}

/* Text */
.text-gnani {
  color: #3561fc !important;
}

.text-gold {
  color: #fed954 !important;
}

.text-light {
  text-align: center;
  font: normal normal normal Avenir;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.text-blue {
  color: #329fff !important;
}

/* Backgrounds */
.layout-bg {
  border-radius: 2px !important;
  box-shadow: 12px 15px 11px #00000028 !important;
  background-color: #283c4b !important;
}

.bg-gnani {
  background-color: #3561fc !important;
}

.bg-gold {
  background: transparent linear-gradient(180deg, #fed954 0%, #feab1c 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 12px 15px 11px #00000028 !important;
}

.bg-main {
  background-color: #17222a !important;
}

.bg-second {
  background-color: #30485a !important;
}

.bg-second-2 {
  background-color: #3a586e !important;
}

.bg-layout {
  background-color: #283c4b !important;
}

.bg-darker {
  background-color: #13212b !important;
}

.bg-blue {
  background-color: #329fff !important;
}

.bg-black {
  background: #241A18 !important;
}

.bg-slot {
  background: #6CC480 !important;
}

.bg-form {
  background: #BDD56C !important;
}

.bg-action {
  background: rgba($color: #5F5F60, $alpha: 0.7) !important;
}

.bg-black {
  background: #241a18 !important;
}

.bg-light_blue {
  background: #A5B3F5 !important;
}

.bg-lgt_blue {
  background: #96bdef !important;
}

.bg-pink {
  background: #f2a59b 0% 0% !important;
}

.bg-orange {
  background-color: #ff4800 !important;
}
.bg-purple {
  background: #00b7ff !important;
}
.bg-magenta {
  background: #e01fbe !important;
}
.bg-green {
  background: #1fe041 !important;
}

.bg-chat-orange {
  background-color: #ee8f31 !important;
}

.phill-gold {
  background: transparent linear-gradient(180deg, #fed954 0%, #feab1c 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 12px 15px 11px #00000028;
  border-radius: 2px !important;
  color: #000 !important;
  padding: 0.2rem;
}

.phill-orange {
  background: #ee8f31 0% 0% no-repeat padding-box !important;
  border-radius: 2px !important;
  color: #ffffff !important;
  padding: 0.2rem;
}

.phill-blue {
  background: #329fff 0% 0% no-repeat padding-box !important;
  border-radius: 2px !important;
  color: #ffffff !important;
  padding: 0.2rem;
}

/*Buttons*/

.btn-info {
  background: #ffcd00 0% 0% no-repeat padding-box;
  opacity: 1;
}

.btn-warning {
  background: #ff6565 0% 0% no-repeat padding-box;
  opacity: 1;
}

.btn-bluer {
  background: #041ffb 0% 0% no-repeat padding-box !important;
  border-radius: 0.4rem !important;
  font: $f-900-1620-av !important;
}

.btn-blue {
  background: #329fff 0% 0% no-repeat padding-box !important;
  border-radius: 2px !important;
  font: normal normal normal 14px/22px Avenir !important;
  color: #ffffff !important;
}

.btn-task {
  background: #838383 0% 0% no-repeat padding-box;
  opacity: 1;
}

.btn-gold {
  background: transparent linear-gradient(180deg, #fed954 0%, #feab1c 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 12px 15px 11px #00000028 !important;
  border-radius: 2px !important;
  color: #ffffff !important;
  font: normal normal 900 14px/22px Avenir;
}

.btn-gradient {
  background: transparent linear-gradient(180deg, #fed954 0%, #feab1c 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}

.btn-user {
  background-color: #17222a !important;
  border: 0 !important;
}

.badge-inactive {
  background: transparent linear-gradient(334deg, #d0dbe6 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 12px 12px 22px #0e1f2b8a;
  opacity: 1;
}

.main-block {
  background: #283c4b 0% 0% no-repeat padding-box;
}

.main-block .input-group-text {
  background: #283c4b 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 1;
}

.main-block input::placeholder {
  text-align: left;
  font: normal normal 300 16px/30px Avenir;
  letter-spacing: 0px;
  color: #d0dbe6;
  opacity: 0.28;
}

.main-block input {
  text-align: left;
  font: normal normal 300 16px/30px Avenir;
  letter-spacing: 0px;
  color: #d0dbe6;
  opacity: 1;
}

.node-body-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  // padding-bottom: 0.25rem;
}
.user-body-input {
  height: 80%;
  align-self: center;
  border: none;
}

.toolBox {
  z-index: 5 !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 4px #000000c3;
  border: 1px solid #70707061;
  border-radius: 7px;
  width: 240px;
  height: 42px;
  position: absolute;
  left: 1.5rem;
  top: 8rem;
}
.add-button {
  //display: none;
  visibility: hidden;
  z-index: 3;
  position: absolute;
}

.scroll::-webkit-scrollbar{
  display: block !important;
  width: 0.7rem;
}

.scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: inherit;
}
.scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: inherit;
}

.dropdown-scroll::-webkit-scrollbar{
  display: block !important;
  width: 0.7rem;
}

.dropdown-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
  border-radius: inherit;
  border-top-left-radius: initial !important;
  border-bottom-left-radius: initial !important;
  background: snow;
}
.dropdown-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: inherit;
  border-top-left-radius: initial !important;
  border-bottom-left-radius: initial !important;
}

.react-flow__node:hover :last-child {
  visibility: visible;
}

.react-dropdown-select-content ::placeholder {
  color: rgb(88, 87, 87);
  width: 100% !important;
}
.react-dropdown-select-dropdown.react-dropdown-select-dropdown-position-bottom {
  left: 15rem;
  width: 20rem;
  top: -0.1rem;
  border-radius: 0.5rem;
  color: #000;
}

.react-dropdown-select-dropdown.react-dropdown-select-dropdown-position-bottom::-webkit-scrollbar {
  display: block !important;
  width: 0.7rem;
}
 
.react-dropdown-select-dropdown.react-dropdown-select-dropdown-position-bottom::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: inherit;
}
 
.react-dropdown-select-dropdown.react-dropdown-select-dropdown-position-bottom::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: inherit;
}

.react-dropdown-select-dropdown.react-dropdown-select-dropdown-position-bottom::-webkit-scrollbar {
  display: block !important;
  width: 0.7rem;
}
 
.react-dropdown-select-dropdown.react-dropdown-select-dropdown-position-bottom::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: inherit;
}
 
.react-dropdown-select-dropdown.react-dropdown-select-dropdown-position-bottom::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: inherit;
}

.react-dropdown-select-dropdown.react-dropdown-select-dropdown-position-bottom
  > span {
  color: black;
  padding-left: 1rem;
}
.options:hover {
  background: #b6b5b523;
}
.react-dropdown-select-input {
  &::placeholder {
    color: #ffffff !important;
  }
  height: 2rem;
}
.react-dropdown-select-input:focus {
  outline: none;
  border: none;
}
.react-dropdown-select-input::after {
  outline: none;
  border: none;
}

.react-dropdown-select-content { 
  width: 100%;
  span {
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    font: $f-900-2031-av;
  }
}

.react-dropdown-select {
  margin: 0.3rem 0;
  &:focus-within {
    background: #00000047;
    box-shadow: none !important;
  }
}

.node-body-font {
  font: $f-900-1620-av
}
.event-header-font {
  font: $f-900-2031-av
}

// .react-flow__node.react-flow__node-Sa.selectable{
//   opacity: 1 !important;
// }
