@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.entity-list {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 14rem;
  height: 18rem;
  background: white;
  z-index: 4;
}
.entity-list > div {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100% - 2rem);
}

.header {
  font-size: 0.9rem;
  font-weight: 500;
}

.list:hover {
  background: $ghost-white-clr 0% 0% no-repeat padding-box;
}

.validate-data-btn:hover,
.validate-data-btn:focus {
  background: $ultramarine-clr 0% 0% no-repeat padding-box;
}

.intent-pagination-text{
  font: normal normal medium 16px/20px Poppins;
letter-spacing: 0px;
color: #8E8E8E;
}
