@font-face {
  font-family: PoppinsMedium;
  src: url(../fonts/Poppins-Font/poppins_ff/Poppins-Medium.ttf);
}

@font-face {
  font-family: PoppinsRegular;
  src: url(../fonts/Poppins-Font/poppins_ff/Poppins-Regular.ttf);
}

@font-face {
  font-family: PoppinsSemiBold;
  src: url(../fonts/Poppins-Font/poppins_ff/Poppins-SemiBold.ttf);
}

$f-900-1519-av: normal normal 500 15px/19px PoppinsMedium;
$f-900-2027-av: normal normal 500 20px/27px PoppinsMedium;
$f-900-1520-av: normal normal 500 15px/20px PoppinsMedium;
$f-900-1522-av: normal normal 500 15px/22px PoppinsMedium;
$f-900-1531-av: normal normal 500 15px/31px PoppinsMedium;
$f-900-1620-av: normal normal 500 16px/20px PoppinsMedium;
$f-900-1719-av: normal normal 500 17px/19px PoppinsMedium;
$f-900-1720-av: normal normal 500 17px/20px PoppinsMedium;
$f-900-1723-av: normal normal 500 17px/23px PoppinsMedium;
$f-900-1727-av: normal normal 500 17px/27px PoppinsMedium;
$f-900-1731-av: normal normal 500 17px/31px PoppinsMedium;
$f-900-1819-av: normal normal 500 18px/19px PoppinsMedium;
$f-900-1926-av: normal normal 500 19px/26px PoppinsMedium;
$f-900-1931-av: normal normal 500 19px/31px PoppinsMedium;
$f-900-2031-av: normal normal 500 20px/31px PoppinsMedium;
$f-900-2231-av: normal normal 500 22px/31px PoppinsMedium;
$f-900-2531-av: normal normal 500 25px/31px PoppinsMedium;
$f-900-2636-av: normal normal 500 26px/36px PoppinsMedium;
$f-900-2831-av: normal normal 500 28px/31px PoppinsMedium;
$f-bld-1518-av: normal normal 500 15px/18px PoppinsMedium;
$f-bld-2631-av: normal normal 500 26px/31px PoppinsMedium;
$f-nm-1719-av: normal normal 400 17px/19px PoppinsRegular;
$f-nm-1819-av: normal normal 500 18px/19px PoppinsRegular;
$f-nm_1722-av: normal normal 400 17px/22px PoppinsRegular;
$f-nm-1416-av: normal normal 400 14px/16px PoppinsRegular;
$f-nm-1420-av: normal normal 400 14px/20px PoppinsRegular;
$f-nm-1518-av: normal normal 400 15px/18px PoppinsRegular;
$f-nm-1520-av: normal normal 400 15px/20px PoppinsRegular;
$f-nm-1720-av: normal normal 400 17px/20px PoppinsRegular;
$f-nm-1723-av: normal normal 400 17px/23px PoppinsRegular;
$f-nm-1731-av: normal normal 400 17px/31px PoppinsRegular;
$f-900-2520-av: normal normal 500 25px/20px PoppinsMedium;
$f-900-2227-av: normal normal 500 22px/27px PoppinsMedium;
$f-900-1734-av: normal normal 500 17px/34px PoppinsMedium;
$f-900-1431-av: normal normal 500 14px/31px PoppinsMedium;
$f-900-1231-av: normal normal 500 12px/31px PoppinsMedium;
$f-900-1622-av: normal normal 500 16px/22px PoppinsMedium;
$f-600-1523-po: normal normal 500 15px/23px PoppinsMedium;
$f-900-1131-av: normal normal 500 11px/31px PoppinsMedium;
$f-900-1631-av: normal normal 500 16px/31px PoppinsMedium;
$f-900-1831-av: normal normal 500 18px/31px PoppinsMedium;
$f-900-1620-av: normal normal 500 16px/20px PoppinsMedium;

$f-nm-1726-av: normal normal 400 17px/26px PoppinsRegular;
$f-md-1420-av: normal normal normal 14px/20px PoppinsRegular;
$f-md-1519-av: normal normal normal 15px/19px PoppinsRegular;
$f-md-1527-av: normal normal normal 15px/27px PoppinsRegular;
$f-md-1530-av: normal normal normal 15px/30px PoppinsRegular;
$f-md-1619-av: normal normal normal 16px/19px PoppinsRegular;
$f-md-1719-av: normal normal normal 17px/19px PoppinsRegular;
$f-md-1717-av: normal normal normal 17px/17px PoppinsRegular;
$f-md-1722-av: normal normal normal 17px/22px PoppinsRegular;
$f-nm-1731-av: normal normal normal 17px/31px PoppinsRegular;
$f-nm-1531-av: normal normal normal 15px/31px PoppinsRegular;
$f-md-2531-po: normal normal normal 25px/31px PoppinsRegular;
$f-md-1719-po: normal normal normal 17px/19px PoppinsRegular;
$f-md-1726-po: normal normal normal 17px/26px PoppinsRegular;
$f-nm-1726-po: normal normal normal 17px/26px PoppinsRegular;
$f-nm-1625-po: normal normal normal 16px/25px PoppinsRegular;
$f-nm-1631-po: normal normal normal 16px/31px PoppinsRegular;
$f-md-1523-po: normal normal normal 15px/23px PoppinsRegular;
$f-md-1523-po: normal normal normal 14px PoppinsRegular;

$f-900-4849-po: normal normal 900 48px/49px PoppinsSemiBold;
$f-900-1620-po: normal normal 900 16px/20px PoppinsSemiBold;
$f-900-25-po: normal normal 900 25px PoppinsSemiBold;
$f-900-17-po: normal normal 900 17px PoppinsSemiBold;
$f-900-2636-po:normal normal 900 26px/36px PoppinsSemiBold;
$f-900-1723-po:normal normal 900 17px/23px PoppinsSemiBold;

$f-600-2030-po: normal normal 600 20px/30px PoppinsSemiBold;
$f-md-3839-po: normal normal 500 38px/39px PoppinsMedium;
$f-md-2039-po: normal normal 500 20px/39px PoppinsMedium;
$f-md-3539-po: normal normal 500 35px/39px PoppinsMedium;
$f-md-1719-po: normal normal 500 17px/19px PoppinsMedium;
$f-md-1726-po: normal normal 500 17px/26px PoppinsMedium;
$f-nm-1625-po: normal normal 400 16px/25px PoppinsMedium;

$f-md-2439-po: normal normal 600 24px/39px PoppinsMedium;
$f-nm-1639-po: normal normal 400 16px/39px PoppinsRegular;
$f-md-1419-po: normal normal 500 14px/19px PoppinsMedium;
$f-md-1421-po: normal normal 500 14px/21px PoppinsMedium;
$f-nm-1421-po: normal normal 400 14px/21px PoppinsMedium;
$f-md-1539-po: normal normal 500 15px/39px PoppinsMedium;
$f-md-1726-Po: normal normal 500 17px/26px PoppinsMedium;
$f-nm-1726-Po: normal normal normal 17px/26px PoppinsRegular;

$f-md-1530-po:normal normal 500 15px/30px PoppinsMedium;
$f-nm-1523-po:normal normal 400 15px/23px PoppinsRegular;

$f-bld-1520-po:normal normal bold 15px/20px PoppinsSemiBold;
$f-nm-1720-po:normal normal 400 17px/20px PoppinsRegular;
$f-md-1720-po:normal normal 500 17px/20px PoppinsMedium;
$f-bld1-1720-po:normal normal 900 17px/20px PoppinsSemiBold;

$f-sbld-1720-po:normal normal 600 17px/20px PoppinsSemiBold;

$f-nm-1723-po:normal normal 400 17px/23px PoppinsRegular;
$f-md-1723-po:normal normal 500 17px/23px PoppinsMedium;
$f-bld-1723-po:normal normal 700 17px/23px PoppinsSemiBold;

$f-nm-1520-po:normal normal 400 15px/23px PoppinsRegular;
$f-sbld-1623-po:normal normal 600 16px/23px PoppinsSemiBold;
$f-600-1726-po: normal normal 600 16px/26px PoppinsSemiBold;
$f-500-1726-po: normal normal 500 16px/26px PoppinsMedium;

$f-nm-13-po: normal normal normal 13px/25px PoppinsRegular;

$f-nm-1015-po:normal normal 400 10px/15px PoppinsRegular;
$f-md-1018-po:normal normal 500 10px/18px PoppinsMedium;


$f-md-12-po: normal normal 500 12px/31px PoppinsMedium;
$f-nm-12-po: normal normal normal 12px PoppinsRegular;
$f-nm-1218-po: normal normal normal 12px/18px PoppinsRegular;
$f-md-1220-po: normal normal 500 12px/20px PoppinsMedium;

$f-md-14-po: normal normal 500 14px PoppinsMedium;
$f-md-1420-po: normal normal 500 14px/20px PoppinsMedium;
$f-nm-14-po: normal normal normal 14px PoppinsRegular;
$f-nm-1421-po: normal normal normal 14px/21px PoppinsRegular;

$f-md-16-po: normal normal 500 16px PoppinsMedium;
$f-nm-16-po: normal normal normal 16px PoppinsRegular;
$f-nm-1631-po: normal normal normal 16px/31px PoppinsRegular;

$f-nm-1625-po: normal normal normal 16px/25px PoppinsRegular;
$f-md-1620-po:normal normal 500 16px/20px PoppinsMedium;
$f-md-1624-po:normal normal 500 16px/24px PoppinsMedium;

$f-nm-15-po: normal normal normal 15px PoppinsRegular;
$f-nm-1519-po: normal normal normal 15px/19px PoppinsRegular;
$f-md-15-po: normal normal 500 15px PoppinsRegular;
$f-md-1520-po: normal normal 500 15px/20px PoppinsMedium;
$f-md-1523-po: normal normal 500 15px/23px PoppinsMedium;
$f-600-1519-po: normal normal 600 15px/19px PoppinsMedium;

$f-md-17-po: normal normal 500 17px PoppinsMedium;
$f-md-1734-po: normal normal 500 17px/34px PoppinsMedium;
$f-600-1731-po: normal normal 600 17px/31px PoppinsMedium;
$f-md-1727-po: normal normal 500 17px/27px PoppinsMedium;
$f-md-1715-po: normal normal 500 17px/15px PoppinsMedium;
$f-nm-17-po: normal normal normal 17px PoppinsRegular;

$f-md-18-po: normal normal 500 18px PoppinsMedium;
$f-nm-18-po: normal normal 400 18px PoppinsRegular;
$f-sbld-1827-po: normal normal 600 18px/27px PoppinsSemiBold;


$f-sbld-2436-po: normal normal 600 24px/36px PoppinsSemiBold;

$f-sbld-20-po: normal normal 500 20px PoppinsSemiBold;
$f-sbld-25-po: normal normal 500 25px PoppinsSemiBold;
$f-sbld-2027-po: normal normal 500 20px/27px PoppinsSemiBold;
$f-bld-2031-po: normal normal 600 20px/31px PoppinsSemiBold;
$f-sbld-2531-po: normal normal 600 25px/31px PoppinsSemiBold;







// * {
//   font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
//     "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//     sans-serif;
//   // letter-spacing: 0.5px;
// }
