.alert-light {
  box-shadow: 0 1rem 3rem #7f7d7d !important;
}
.alert {
  padding: 0.3rem 0.3rem !important;
}
.col-sm-4 {
    width: 28% !important;
}
.btn-close {
  background: transparent url(../../assets/icons/close-btn.svg) center/1em auto no-repeat !important;
  top: 15% !important;
  right: 1% !important;
  opacity: 1 !important;
}
