@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.bg-smoke {
  background: $bg-smoke-clr;
}

.create-bot-input {
  background: $white-clr 0% 0% no-repeat padding-box;
  border: 1px solid $dim-gray-border-clr;
  color: $tangaroa-clr;
  font: $f-md-1719-av;
  // font-family: Avenir;
  padding: 0.5rem 0;
  width: 100%;
}

.create-bot-input::placeholder,
.create-bot-secondary-text {
  color: $waikawa-grey-blue-clr;
  font: $f-md-15-po;
  // font-family: PoppinsRegular;
}

.create-bot-plus-icon-container {
  backdrop-filter: blur(50px);
  background: $pure-black-clr 0% 0% no-repeat padding-box;
  border-radius: 50%;
  box-shadow: 0px 3px 6px $black-clr;
  display: flex;
  height: 3.9rem;
  justify-content: center;
  opacity: 0.65;
  width: 3.9rem;
  -webkit-backdrop-filter: blur(50px);
}

.create-bot-channel-row-container {
  display: flex;
  justify-content: space-between;
  margin-top: 0.75rem;
}

.channel-icon-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  // height: 7rem;
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
  width: 7rem;
}

.selected-bot-channel {
  background: $white-clr 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 1px solid $waikawa-grey-blue-bg-clr;
}

.icon-select-font {
  font: $f-nm-16-po;
}

.create-bot-call-type {
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 6rem;
  justify-content: center;
  margin: 0 1rem;
  width: 6rem;
}

.create-bot-call-type > p {
  color: $black-pearl-blue-clr;
  font: $f-900-1522-av;
  margin: 0.5rem 0 0;
}

.selected-call-type {
  background: $white-clr 0% 0% no-repeat padding-box;
  border: 1px solid $waikawa-grey-blue-bg-clr;
}

.create-bot-inp-label {
  color: $tangaroa-clr;
  cursor: pointer;
  font: $f-md-1719-av;
}

.create-bot-btn {
  border-radius: 5px;
  border: 1px solid $blue-zodiac-blue-clr;
  font: $f-md-16-po;
  height: 43px;
  width: 164px;
}

.create-bot-btn-ok {
  background: $midnight-express-blue-clr 0% 0% no-repeat padding-box;
  color: $white-clr;
}

.create-bot-btn-ok:hover {
  color: $white-clr;
}
