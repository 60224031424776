@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.left-window {
  position: relative;
}

.contactUs-img {
  position: absolute;
  // width: 38vw;
  // left: 8vw;
  bottom: 0;
}

body,
html {
  margin: 0;
  overflow: hidden;
  height: 100%;
}

.inp-placeholder-clr-contactUs {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e5e5e5;
  border-radius: 11px;
  opacity: 1;
  padding: 3% !important;
  font: $f-md-1719-po;
}

.inp-placeholder-clr-contactUs::placeholder {
  font: $f-md-1719-po;
  color: #a7abaf;
  text-align: left;
  letter-spacing: 0px;
}
.contactus-form {
  min-width: 70%;
}

.login-btn {
  background: #5772ef 0% 0% no-repeat padding-box;
  border-radius: 11px;
  opacity: 1;
  padding: 3%;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font: $f-md-1719-po;
}

.login-link {
  color: #284bed;
  text-align: left;
  font: $f-md-1719-po;
  letter-spacing: 0px;
  opacity: 1;
}

.login-have-acc-text {
  text-align: left;
  font: $f-md-1719-po;
  letter-spacing: 0px;
  color: #34363b;
  opacity: 1;
}

.contactus-right-side {
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
}

.left-panel-text {
  width: 40% !important;
}
