@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.bot-dropdown-list {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 5% 7%;
}

.bot-dropdown-list:hover {
  background: $light-grey-clr 0% 0% no-repeat padding-box;
  font-weight: 600;
}

.bot-dropdown-list span {
  color: $night-rider-clr;
  flex-grow: 0.9;
  font-size: 0.7rem;
  font: $f-md-1420-av;
}

.bot-statistics-container > h6 {
  color: $black-pearl-blue-clr;
  font-family: PoppinsMedium;
}

.bot-status-container {
  margin-top: 0.95rem;
}

.bot-status-container > h6,
.bot-web-sdk-container > h6 {
  color: $black-pearl-blue-clr;
  font-family: PoppinsMedium;
}

.bot-status-container > p,
.bot-web-sdk-container > p {
  color: $grey-clr;
  font-family: PoppinsRegular;
}

.bot-web-sdk-script-container {
  background: $mortar-grey-clr 0% 0% no-repeat padding-box;
  border-radius: 2px 0px 0px 2px;
  color: $white-clr;
}

.bot-web-sdk-script-container > p {
  font-size: 0.85rem;
  padding: 0.65rem 1rem;
}

.bot-web-sdk-icon-container {
  align-items: center;
  background: $wild-blue-yonder-blue-clr 0% 0% no-repeat padding-box;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 7rem;
}

.home-bot-bio {
  color: $nero-grey-clr;
  font-family: PoppinsRegular;
  font-size: 0.9rem;
}

.home-right-content-header-container {
  color: $nero-grey-clr;
  flex-grow: 1;
  font-family: PoppinsMedium;
  margin: 0;
  text-align: center;
}

.home-bot-bio-label {
  color: $grey-clr;
  display: inline-block;
  font: normal normal 14px PoppinsRegular;
  width: 10rem;
  // font-size: 14px;
}

.home-bot-bio-value {
  color: $nero-grey-clr;
  font-family: PoppinsRegular;
  font-size: 13px;
}

.shared-bot-modal-title {
  font: $f-900-2636-av;
  color: $nero-clr;
  text-align: center;
}

.shared-bot-modal-label {
  font: $f-900-1723-av;
  color: $nero-clr;
}

.shared-bot-modal-inp {
  background: $white-clr 0% 0% no-repeat padding-box;
  border-radius: 2px;
  border: 1px solid $black-border-clr;
  height: 45px;
  width: 240px;
}

.shared-bot-modal-inp::placeholder {
  font: $f-nm-1723-av;
  color: $suva-grey-grey;
}

.shared-modal-container,
.duplicate-modal-container {
  background: $white-clr 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 16px $black-clr;
  border-radius: 21px;
  padding: 2rem;
  margin-top: 35vh;
}

.duplicate-modal-container {
  border-radius: 0;
}

.modal-content {
  border: none !important;
}

.shared-modal-confirm-btn {
  background: $chambray-clr 0% 0% no-repeat padding-box;
  border-radius: 2px;
  color: $white-clr;
  font: $f-900-1520-av;
  height: 46px;
  width: 139px;
  padding-top: 10px;
}

.shared-modal-confirm-btn:hover {
  color: $white-clr;
}

.shared-modal-cancel-btn {
  font: $f-900-1520-av;
  color: $chambray-clr;
  height: 46px;
  width: 139px;
}

.shared-modal-cancel-btn:hover {
  color: $chambray-clr;
}

.shared-user-info-container {
  background: $alice-blue-clr 0% 0% no-repeat padding-box;
  border-radius: 14px;
  font-family: PoppinsRegular;
  height: 38px;
  padding: 0.5rem 1rem;
  width: fit-content;
}

.shared-user-info-title {
  color: $black-pearl-blue-clr;
  font-family: PoppinsRegular;
  margin-bottom: 0.3rem;
}

.shared-user-desc-container {
  color: $grey-clr;
  font-family: PoppinsRegular;
}

.duplicate-modal-confirm-btn {
  background: $east-bay-blue-clr 0% 0% no-repeat padding-box;
  border-radius: 0.25rem;
  border: 1px solid $ultramarine-clr;
  box-shadow: 0px 0px 2px $black-clr;
  color: $white-clr;
  height: 2.55rem;
  width: 10rem;
}

.duplicate-modal-confirm-btn:hover {
  color: $white-clr;
}
