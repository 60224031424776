@import "../../assets/scss/fonts.scss";
@import "../../assets/scss/colors.scss";

.no-user-cont {
    width: 500px;
    height: 245px;
}
.no-user-info {
    font: $f-sbld-2027-po;
    color: #181818;
}
.no-user-small-text {
    font: $f-md-1734-po;
    color: #8F8F8F;
}
.org-set-font {
    font: $f-sbld-1827-po;
}
.org-set-email-font {
    font: $f-nm-1421-po;
    color: #8F8F8F;
}
.org-set-user-head {
    font: $f-sbld-1827-po;
    color: #181818;
}
.font-1527 {
    font: $f-md-1527-av;
}
.font-1631 {
    font: $f-nm-1631-po;
}
.font-1421 {
    font: $f-nm-1421-po;
}
.font-md-1624 {
    font: $f-md-1624-po;
}
.font-1731 {
    font: $f-nm-1731-av;
}
.font-nm-14 {
    font: $f-nm-14-po;
}
.font-nm-12 {
    font: $f-nm-12-po;
}
.font-md-18 {
    font: $f-md-18-po;
}
.org-user-detail-card-header {
    font: $f-sbld-1827-po;
}
.bot-count-cont {
    height: 22px;
    padding: 2px 8px;
    background: #F2F4FF;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.user-search-icn-pos {
    position: relative;
    left: 25px;
    color: #BCBCBC;
}
.user-selected {
    background: #F2F4FFA5 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 0px #F5F5F5;
    opacity: 1;
}
.left-panel {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px #00000029;
}
.right-panel {
    background: #fafafa 0% 0% no-repeat padding-box;
}
.org-user-detail-card {
    // height: 172px;
    background: #FFFFFF;
    // border: 1px solid #7070702B;
    border-radius: 16px;
}

.org-user-detail-card label {
    color: #8F8F8F;
}

.bot-card {
    height: 72px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
}
.bot-card p {
    font: $f-md-1420-po;
    color: #343538;
}
.bot-card span {
    color: #838383;
    font: $f-nm-1218-po;
}
.selected-bg {
    background: #F2F4FF;
}
.org-set-user-status-cont {
    height: 29px;
    background: #F2F4FF;
    border-radius: 16px;
    font: $f-nm-1421-po;
    color: #0D2981;
    display: flex;
    align-items: center;
    justify-content: center;
}
.org-set-add-user-btn {
    height: 36px;
    background: #0D2981;
    border-radius: 8px;
    font: $f-md-1420-po;
    padding: 8px 16px;
    color: #FFFFFF;
}
.bor-btm {
border-bottom: 1px solid rgba(112, 112, 112, 0.13);
}
.bor-top{
    border-top: 1px solid #CDCDCD;
}
.org-search-cont {
    // width: 150px;
    display: flex;
    align-items: center;
}
.org-set-search-inp {
    height: 40px;
    font: $f-md-14-po;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    padding-left: 2rem;
    padding-right: 2rem;

    &::placeholder {
        font: $f-nm-1726-po;
        letter-spacing: 0px;
        color: #707070;
        opacity: 0.47;
    }
}
.seacrh-icn-position {
    position: relative;
    color: #BCBCBC;
    left: 25px;
}
.seacrh-cancel-position {
    position: relative;
    color: #BCBCBC;
    right: 25px;
    cursor: pointer;
}
.add-modal-header {
    font: $f-sbld-1827-po;
}
.del-modal-info {
    font: $f-md-1727-po;
    color: #8F8F8F;
}
.modal-label-font {
    font: $f-md-1727-po;
    color: #222222;
    text-align: left;
}
.modal-msg-font {
    font: $f-md-1727-po;
    color: #000000;
}
.modal-inp {
    height: 44px;
    font: $f-nm-16-po;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #D0D5DD;

    &::placeholder {
        color: #949494;
    }
}
.add-user-cancel-btn {
    width: 92px;
    height: 40px;
    color: #0D2981;
    font: $f-md-1420-po;
}
.add-user-confirm-btn {
    height: 40px;
    font: $f-md-1420-po;
    color: #FFFFFF;
    background: #0D2981;
    border-radius: 8px;
    padding: 10px 20px;
}
.outline-btn {
    height: 40px;
    border: 1px solid #0D2981;
    border-radius: 2px;
    color: #0D2981;
    font: $f-md-1727-po;   
}
.dropdown-color-btn {
    height: 40px;
    color: #FFFFFF;
    font: $f-md-1727-po;
    background: #0D2981 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
}
.del-modal-sub-header {
    font: $f-md-1624-po;
    color: #181818;
}
.truncate-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.add-bot-name {
    font: $f-md-1420-po;
    color: #343538;
}
.add-bot-industry {
    color: #838383;
    font: $f-nm-1218-po;
}
.add-bot-modal-inp {
    height: 48px;
    border: 1px solid #0D2981;
    border-radius: 8px;
    font: $f-md-1520-po;
    padding-left: 2.5rem;
    padding-right: 1.5rem;
    &::placeholder {
        color: #8F8F8F;
    }
}
.add-bot-modal-inp-icn-pos {
    position: relative;
    left: 16px;
    top: 37px;
    color: #8F8F8F;
}
.pagination-cont {
    height: 50px;
    background: #FAFAFA;
    position: absolute;
    bottom: 0px;
    left: 60%;
}
.read-write-btn {
    font: $f-md-1420-po;
    color: #0D2981;
}