@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.synonym-container {
  position: relative;
  left: 2rem;
}

.synonym {
  width: 286px;
  height: 41px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 6px #0000000d;
  border: 1px solid #f1f1f1;
  border-radius: 3px;
  font: $f-nm-16-po;
}
.synonym::placeholder {
  font: $f-nm-16-po;
  opacity: 0.47;
}

.synonymUnSelected {
  border: 1px solid #7983b4;
  border-radius: 7px;
  color: #7983b4;
  font: $f-nm-16-po;
}
.synonymSelected {
  border: 2px solid #d35555;
  border-radius: 7px;
  color: #d35555;
  font: $f-nm-16-po;
}
.delete-synonym-btn {
  width: 171px;
  height: 39px;
  box-shadow: 0px 0px 2px #00000029;
  border: 1px solid #51597e;
  border-radius: 3px;
  color: white;
  background: #495072;
  font: $f-nm-13-po;
}
.cancel-btns {
  width: 70px;
  height: 39px;
  border: 1px solid #505981;
  border-radius: 2px;
  font: $f-nm-13-po;
  color: #505981;
}
.del-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.del-container p {
  color: #d35555;
  font: $f-nm-14-po;
  margin-top: 2px;
}

.phrase:focus-within {
  background: #ededed 0% 0% no-repeat padding-box;
}

.synonym-font p {
  font: $f-nm-14-po;
  color: #ACACAC;
}
