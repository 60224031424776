@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.body {
  background: #f5f6f7;
}

.tab {
  font: $f-600-1726-po;
  border-bottom: 3px solid #3e4e91;
  display: block;
}

.tab-font {
  font: $f-500-1726-po;
}

.input {
  // width: 25rem;
  font: $f-md-1719-po;
  height: 3.5rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid rgba(112, 112, 112, 0.15);
  border-radius: 4px;  // font: normal normal 900 17px/17px Avenir;
  &:focus{
    border: 2px solid #3E4E91;
    box-shadow: none;
  }
}
.input::placeholder {
  color: #707070;
  opacity: 0.47;
}
.label {
  font: $f-900-17-po;
  color: #162128;
}
.email-inpt {
  // width: 25rem;
  font: $f-md-1719-po;
  height: 3.5rem;
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border: 2px solid rgba(112, 112, 112, 0.15);
  border-radius: 4px;  color: #606060;
  font: $f-900-17-po;
}
.btn-purple {
  width: 11.125rem;
  height: 2.538rem;
  color: #ffffff;
  background: #51597e 0% 0% no-repeat padding-box;
  border-radius: 4px;
  font: $f-900-1723-po;
}

.profile-img-container{
  width: 18rem;
  height: 18rem;
  position: absolute;
  top: 160px;
  left: 40px;
}
.profile-img {
  height: 14rem;
  width: 14rem;
}
.user-info-cont{
  position: absolute;
  top: 440px;
  left: 95px;
  width: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.user-inp-card{
  background: #ffffff;
  border-radius: 10px;
}

.img-edit-btn {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 231px;
  top: 370px;
  background: #3E4E91;
  display: flex;
  justify-content: center;
  align-items: center;
}

.save-btn {
  width: 5.125rem;
  color: #51597e;
  box-shadow: 0px 0px 2px #00000029;
  border: 1px solid #51597e;
  border-radius: 3px;
  font: $f-900-1620-po;
}

.password {
  position: relative;
}
.password i {
  position: absolute;
  left: 39rem;
  top: 3.9rem;
}
.profile-heading {
  font: $f-900-25-po;
  color: #162128;
}
.font-edit {
  font: $f-900-17-po;
}
.text-labels {
  font: $f-500-1726-po;
  color: #222222;
}
.modal-heading {
  font: $f-900-2636-po;
  color: #222222;
}
.btn-confirm-modal {
  color: #fff;
  font: $f-bld-1723-po;
  width: 202px;
  height: 48px;
  background: #3E4E91;
  border-radius: 4px;
}
.btn-cancel-modal {
  color: #505981;
  font: $f-bld-1723-po;
  width: 136px;
  height: 48px;
  border: 1px solid #3E4E91;
  border-radius: 4px;
}
.profile-name-font {
  font: $f-600-2030-po
}
.email-font{
  font: $f-md-2039-po;
  color: #949494;
}
.desc-font{
  font: $f-md-1726-Po;
  color: #949494;
}
.list:hover{
  background: "#F2F4FF";
}
