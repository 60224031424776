@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.utterence-sentence-dropdown-title {
  color: $east-bay-blue-clr;
  font: $f-900-1723-av;
}

.response-font {
  font: $f-md-1726-Po
}

.utterence-sentence-dropdown-container {
  align-self: flex-start;
  background: $white-clr 0% 0% no-repeat padding-box;
  border-radius: 3px;
  border: 1px solid $white-clr;
  box-shadow: 2px 3px 6px $black-box-shadow-clr;
  // margin-right: 0.5rem;
}

.utterence-sentence-inp {
  border-radius: 3px;
  border: none;
  height: 2.5rem;
  padding: 0 1rem;
  width: 100%;
}

.utterence-sentence-inp::placeholder {
  color: $secondary-dim-gray;
  font: $f-nm-16-po;
  opacity: 0.47;
}

.utterence-sentence-add-section {
  background: $white-clr 0% 0% no-repeat padding-box;
  border-radius: 3px;
  border: 1px solid $white-clr;
  // box-shadow: 2px 3px 6px $black-box-shadow-clr;
}

.utterence-sentence-img {
  background: transparent 0% 0% no-repeat padding-box;
  border-radius: 3px;
  border: 1px solid $bg-white-clr;
  height: 5rem;
  width: 5rem;
}

.u-audio-icn-cont {
  height: 36px;
  width: 36px;
  border-radius: 4px;
  background: #E2E2E2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
}

.utterence-sentence-bottom-panel {
  background: $bg-white-clr 0% 0% no-repeat padding-box;
  // margin-top: 1rem;
  padding: 0 0.5rem;
}

.u-bottom-panel-header {
  font: $f-nm-1723-av;
  color: #51597E;
}

.u-b-p-header-key {
  opacity: 1;
}

.utterence-sentence-bottom-btn {
  background:#2C355C 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: $white-clr;
  font: $f-md-1722-av;
}

.utterence-sentence-bottom-btn:hover {
  color: $white-clr;
}

.u-s-bottom-content-holder {
  background: $white-smoke-white-clr-2 0% 0% no-repeat padding-box;
  // padding: 0.5rem;
  width: fit-content;
}

.u-s-b-p-text {
  color: #51597E;
  font: $f-nm-16-po;
  margin-bottom: 0.5rem;
}

.u-s-dropdown-list-btn-title {
  color: $black-pearl-blue-clr;
  font: $f-900-1522-av;
  width: 4rem;
}

.u-s-dropdown-list-inp {
  background: $white-clr 0% 0% no-repeat padding-box;
  border-radius: 3px;
  border: 1px solid $bg-white-clr;
  box-shadow: 2px 3px 6px $black-box-shadow-clr;
  padding: 0.25rem 0.75rem;
  width: 100%;
}

.u-s-dropdown-list-inp::placeholder {
  color: $silver-grey;
  font: $f-nm-1720-av;
}

.u-bottom-panel-inp {
  background: transparent;
  border: none;
  color: #414141;
  font: $f-nm-16-po;
  width: 100%;
}

.res-new-phrase-img-text {
  color: $dark-gray-grey-clr;
  font: $f-nm_1722-av;
  margin-bottom: 0.3rem;
  margin-left: 12px;
}

.res-uploading-audioname-trim {
  text-overflow: ellipsis;
  width: 22rem;
  overflow: hidden;
  white-space: nowrap;
}

.res-new-phrase-channel-container {
  margin-top: 1rem;
  overflow: hidden;
  padding: 0 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 14rem;
}

.res-phrase-delete-btn {
  background: $east-bay-blue-clr 0% 0% no-repeat padding-box;
  border-radius: 3px;
  border: 1px solid $ultramarine-clr;
  box-shadow: 0px 0px 2px $black-clr;
  color: $white-clr;
  // width: 171px;
}

.res-phrase-delete-btn:hover {
  color: $white-clr;
}

.res-phrase-delete-imp-text {
  color: $valencia-red-clr;
  font: $f-nm-12-po;
  margin-bottom: 0;
  width: 209px;
}

.res-phrase-del-btn-border {
  border-radius: 5px;
  border: 2px solid $valencia-red-clr;
}

.isDisabled {
  background-color: #dddddd;
}

.isDisabled-padding {
  padding: 0.45rem;
}

.channel-list-dropdown {
  font: $f-900-1522-av;
  color: #09090d;
}

.new-phrase-dlt-btn{
  width: 87px;
  height: 31px;
  border: 1px solid #3C4980;
  border-radius: 4px;
  font: $f-md-1719-av;
  color: #3D4981;
}

.share-drop-down-search-icon{
  color: #B9B9B9;
  position: relative;
  right: 25px;
}
.share-drop-down-search{
  border: 1px solid #70707019;
  border-radius: 3px,
}
.share-drop-down-cancel {
  width: 119px;
  height: 40px;
  border: 1px solid #465699;
  border-radius: 4px;
  color: #465699;
}
.share-drop-down-confirm{
  width: 119px;
  height: 40px;
  background: #465699 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: white;
}
