@import "../../assets/scss/fonts.scss";
@import "../../assets//scss/colors.scss";

.action-btn-selected{
    width: 131px;
    height:  40px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background: #5E668D;
    color: #FFFFFF;
    font: $f-md-1726-Po;
}
.action-btn-unselected {
    width: 131px;
    height:  40px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background: #FFFFFF;
    color: #5E668D;
    font: $f-md-1726-Po;
}
.react-toggle--checked .react-toggle-track {
    background-color: #5E668D;
}