@import "../../../node_modules/react-notification-alert/dist/animate.css";
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "bootstrap-daterangepicker/daterangepicker.css";

@import "colors.scss";
@import "fonts.scss";

hr {
  background: #a9a9a9 !important;
  height: 0.1rem !important;
}

.px-25 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.my-25 {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}

.btn:focus {
  box-shadow: none !important;
}

input,
textarea {
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

/* Background Colors Start */
.bg-white-smoke {
  background: $white-smoke-clr;
}
/* Background Colors End */

/* Buttons Start */
.outline-btn {
  border-radius: 16px;
  border: 1px solid $chambray-clr;
  color: $chambray-clr;
  font: $f-nm-1518-av;
}

.outline-btn:hover {
  color: $chambray-clr;
}

.primary-btn {
  background: $chambray-clr 0% 0% no-repeat padding-box;
  border-radius: 2px;
  color: $white-clr;
  font: $f-bld-1518-av;
  padding: 3%;
  width: 100%;
}

.primary-btn:hover {
  color: $white-clr;
}
/* Buttons End */

/* Fonts Start */
.normal-text {
  font: $f-nm-1720-av;
  color: $nero-clr;
}

.styled-text {
  color: $chambray-clr;
  font: $f-900-1720-av;
  font-weight: 600;
}
/* Fonts End */

/* Text Colors Start */
.inp-placeholder-clr::placeholder {
  font: $f-nm-1720-av;
  color: $secondary-dim-gray;
  opacity: 0.47;
}
/* Text Colors End */

// disabled button
.btn-disable {
  background: transparent #c3c7de 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 1;
}
#root,
.App {
  height: 100vh;
  // width: 100vw;
}
