@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.settings-bot-img-container {
  align-items: center;
  background: $moody-blue-violet-clr 0% 0% no-repeat padding-box;
  border-radius: 9px;
  display: flex;
  margin: 0 auto 0.75rem;
  height: 150px;
  justify-content: center;
  width: 150px;
}

.settings-sidebar-item-container {
  cursor: pointer;
  padding: 1rem;
}

.settings-sidebar-item-container:hover {
  background: $ghost-white-clr;
}

.settings-sidebar-item-container > p {
  color: $black-clr-2;
  font: $f-900-1620-av;
  margin: 0;
}

.settings-content-layout-label {
  color: $tangaroa-clr;
  font: $f-md-1719-av;
}

.settings-content-inp {
  border: none;
  padding: 0.75rem;
  width: 100%;
  font: $f-md-1722-av;
}

.settings-bot-id-inp {
  background: $white-smoke-white-clr-3 0% 0% no-repeat padding-box;
  font: $f-md-1722-av;
}

.settings-bot-id-inp::placeholder {
  color: $waikawa-grey-blue-clr;
  font: $f-md-1722-av;
}

.settings-content-layout-inp {
  background: $white-clr 0% 0% no-repeat padding-box;
  border: 1px solid $dim-gray-border-clr;
}

.settings-content-layout-inp::placeholder,
.settings-dropdown-span {
  color: $secondary-dim-gray;
  font: $f-nm-1723-av;
}

.settings-bot-img-name {
  color: $tangaroa-clr;
  font: $f-md-1722-av;
}

.settings-channel-col-title {
  color: $waikawa-grey-blue-clr;
  font: $f-900-1522-av;
}

.settings-channel-icon-name {
  font-size: 0.75rem;
  font-weight: bolder;
  margin: 1rem 0 0;
}

.settings-channel-icon {
  width: 3rem;
}

.settings-channel-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.settings-backup-btn {
  background: $midnight-express-blue-clr 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: $white-clr;
  height: 43px;
  width: 10rem;
}

.settings-backup-btn:hover {
  color: $white-clr;
}

.settings-backup-subtitle {
  color: $santas-grey-blue-clr;
  font: $f-md-1719-av;
  margin: 0 0 2rem;
}

.settings-backup-title {
  color: $tangaroa-clr;
  font: $f-md-1719-av;
  margin-left: 0.5rem;
}

.settings-fa-copy {
  background: $white-smoke-white-clr-3 0% 0% no-repeat padding-box;
  cursor: pointer;
  font-size: 1.5rem;
}

.settings-pg-fa-copy-container {
  align-items: center;
  background: $white-smoke-white-clr-3;
  display: flex;
  height: 3rem;
  width: 2.5rem;
  cursor: pointer;
}

.settings-call-container {
  align-items: center;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 6rem;
  justify-content: center;
  margin: 0 1rem;
  width: 6rem;
}

.settings-call-container > p {
  color: $black-pearl-blue-clr;
  font: $f-900-1522-av;
  margin: 0.5rem 0 0;
}

.settings-call-container-selected {
  background: $white-clr 0% 0% no-repeat padding-box;
  border: 1px solid $waikawa-grey-blue-bg-clr;
}

.settings-backup-create-backup-btn {
  background: $midnight-express-blue-clr 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: $white-clr;
  font: $f-md-1722-av;
  height: 43px;
  // width: 164px;
}

.settings-backup-create-backup-btn:hover {
  color: $white-clr;
}

.settings-backup-exp-btns,
.settings-backup-imp-btns {
  border-radius: 5px;
  border: 1px solid $midnight-express-blue-border-clr;
  height: 43px;
  margin: 0 0.5rem;
  width: 100px;
}

.settings-backup-imp-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.settings-backup-create-inp {
  background: $white-clr 0% 0% no-repeat padding-box;
  border-radius: 3px;
  border: 1px solid $bg-white-clr;
  box-shadow: 2px 3px 6px $black-box-shadow-clr;
  height: 43px;
  padding-left: 1rem;
  margin-right: 1rem;
  width: 204px;
}

.settings-backup-note {
  color: $santas-grey-blue-clr;
  font: $f-md-1619-av;
}

.settings-backup-error-msg {
  color: $fire-engine-red-clr;
  font: $f-md-1619-av;
  margin-top: 0.5rem;
}

.settings-share-titles {
  color: $gondola-grey-clr;
  font: $f-900-1926-av;
}

.settings-share-sec-titles {
  color: $gondola-grey-clr;
  font: $f-900-1520-av;
}

.settings-share-inp {
  background: $white-clr 0% 0% no-repeat padding-box;
  border-radius: 2px;
  border: 1px solid $black-border-clr;
  height: 45px;
  padding-left: 1rem;
  width: 12.5rem;
  font: $f-nm-16-po;
}

.settings-share-inp::placeholder {
  color: $suva-grey-grey;
  font: $f-nm-16-po;
}

.settings-share-section {
  background: $white-clr 0% 0% no-repeat padding-box;
  border: 1px solid $dim-gray-border-clr;
  padding: 1rem;
  align-items: end;
  width: 91%;
}

.settings-share-permissions-btns {
  background: $white-clr 0% 0% no-repeat padding-box;
  border-radius: 2px;
  border: 1px solid $black-border-clr;
  height: 47px;
  // width: 135px;
  font: $f-nm-16-po;
  color: $chambray-clr;
}

.settings-share-permissions-btns:hover {
  color: $chambray-clr;
}

.settings-share-selected-access {
  border: 3px solid $slate-grey-clr;
  border-radius: 2px;
}

.settings-share-confirm-btn {
  background: $chambray-clr 0% 0% no-repeat padding-box;
  border-radius: 2px;
  color: $white-clr;
  height: 47px;
  width: 144px;
  font: $f-nm-16-po;
}

.settings-share-confirm-btn:hover {
  color: $white-clr;
}

.settings-share-table-data {
  font: $f-nm-1520-av;
  color: $gondola-grey-clr-2 !important;
}

.settings-share-table-th {
  font: $f-md-1520-po;
  color: #333231;
}

tr {
  .action-btn {
    display: none;
  }
  &:hover {
    .action-btn {
      display: block;
    }
  }
}

.backup-restore-modal {
  background: $white-clr 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 16px $black-clr;
  margin-top: 40vh;
}

.backup-title {
  color: $black-clr-2;
  font: $f-900-2531-av;
}

.backup-actions-container {
  background: $white-smoke-white-clr 0% 0% no-repeat padding-box;
  // height: 288px;
  padding: 5rem 0 0.5rem;
  width: 661px;
}

.backup-action-container {
  align-items: center;
  background: $ultramarine-clr 0% 0% no-repeat padding-box;
  border-radius: 10px;
  display: flex;
  height: 115px;
  justify-content: center;
  margin: 0 2.5rem 1rem;
  width: 115px;
}

.backup-action-name {
  color: $pure-black-clr;
  font: $f-md-1722-av;
  margin: 0;
  text-align: center;
}

.backup-action-btn {
  border-radius: 4px;
  border: 1px solid $jacksons-purple-border-clr;
  color: $jacksons-purple-violet-clr;
  font: $f-md-1619-av;
  width: 104px;
  &:hover {
    color: $white-clr;
    background: $jacksons-purple-violet-clr 0% 0% no-repeat padding-box;
  }
  &:disabled {
    border: 1px solid $echo-blue-clr;
    color: $echo-blue-clr;
  }
}

.backup-create-modal-title {
  color: $nero-clr;
  font: $f-900-2636-av;
  margin: 0;
  text-align: center;
}

.backup-create-modal-inp-label {
  color: $nero-clr;
  font: $f-900-1723-av;
}

.backup-create-modal-inp {
  background: 0% 0% no-repeat padding-box;
  border-radius: 2px;
  border: 1px solid $black-border-clr;
  padding: 0.5rem 1rem;
  width: 280px;
}

.backup-create-modal-inp::placeholder {
  color: $suva-grey-grey;
  font: $f-nm-1723-av;
}

.backup-create-modal-Ok-btn,
.backup-create-modal-cancel-btn {
  border-radius: 2px;
  font: $f-900-1520-av;
  height: 47px;
}

.backup-create-modal-Ok-btn {
  background: $chambray-clr 0% 0% no-repeat padding-box;
  color: $white-clr;
  width: 129px;
  &:hover {
    color: $white-clr;
  }
}

.backup-create-modal-cancel-btn {
  background: 0% 0% no-repeat padding-box;
  border: 1px solid $chambray-clr;
  color: $chambray-clr;
  width: 133px;
  &:hover {
    color: $chambray-clr;
  }
}

.backup-modal-container {
  box-shadow: 0px 3px 16px $black-clr;
}

.backup-modal-success-msg {
  color: $nero-clr;
  font: $f-900-2027-av;
}

.backup-create-warning-msg {
  color: $fire-engine-red-clr;
  font: $f-md-1619-av;
  text-align: center;
}

.backup-action-container-disabled {
  background: $echo-blue-clr 0% 0% no-repeat padding-box;
}

.backup-action-name-disabled {
  color: $echo-blue-clr;
}

.backup-table {
  // width: 659px;
  // height: 271px;
  background: $white-clr 0% 0% no-repeat padding-box;
  border: 3px solid $white-smoke-white-clr;
}

.backup-table-header {
  background: $white-smoke-white-clr 0% 0% no-repeat padding-box;
  color: $black-table-th-clr;
  font: $f-md-1719-av;
}

.backup-table-data,
.backup-table-data-modified {
  font: $f-nm-1719-av;
}

.backup-table-data {
  color: $eclipse-grey-clr-2;
}

.backup-table-data-modified {
  color: $santas-grey-blue-clr;
}

.backup-delete-modal-content {
  color: $nero-clr;
  font: $f-900-1723-av;
  margin: 1rem 5rem;
}

.backup-delete-modal-sub-content {
  font: normal normal 900 17px/23px Avenir;
  color: $danger-clr;
  margin: 1rem 5rem;
}

.backup-table-data-row {
  cursor: pointer;
  height: 3rem;
  &:hover {
    background: $ghost-white-hover-clr;
  }
}

.backup-list-input {
  background: $solitude-blue-clr;
  border-radius: 0.25rem;
  border: none;
  padding: 0.5rem;
}

.backup-list-input::placeholder {
  color: $bright-grey-clr;
  font: $f-md-1719-av;
}

.bot-del-warning {
  font: $f-nm-16-po;
  opacity: 0.47;
}

.bot-del-btn-font {
  font: $f-nm-15-po;
}

.link-cancel-btn {
  width: 91px;
  height: 36px;
  letter-spacing: 0.02em;
  color: #0D2981;
  padding: 8px, 16px, 8px, 16px;
  font: $f-md-1420-po;
}
.link-confirm-btn {
  width: 91px;
  height: 36px;
  color: #fff;
  background: #0D2981;
  border-radius: 4px;
  font: $f-md-1420-po;
  padding: 8px, 16px, 8px, 16px;
}

.text-trim {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 85%;
}
.span-border-right {
  border-right: 2px solid #9ea2a6;
}