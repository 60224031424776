@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.sidebar-container {
  background: $midnight-express-blue-clr;
  width: 6.5rem;
}

.sidebar-links-container {
  flex-direction: column;
  flex-wrap: nowrap;
  height: calc(100% - 4.6rem);
}

.sidebar-nav-link {
  color: #7983b4c7;
  font: $f-md-1530-av;
  margin: 0.5rem 0;
  text-align: center;
  text-decoration: none;
}

.sidebar-nav-link:hover {
  color: #7a8fef;
  .menu-icon {
    fill: #7a8fef !important;
  }
}

.sidebar-nav-link-active {
  color: $white-clr;
}

.sidebar-link-active {
}
