@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.action-input {
  width: 332px;
  height: 42px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #70707026;
  font: $f-nm-16-po;
}
.action-input::placeholder {
  color: #707070;
  opacity: 0.47;
  font: $f-nm-16-po;
}
.token-cont {
  width: 498px;
  background: #f1f1f1 0% 0% no-repeat padding-box;
}
.token {
  background: #e8e8e888 0% 0% no-repeat padding-box;
}
.token-cont-inp {
  width: 285px;
  height: 31px;
  text-align: left;
  border: none;
  color: #414141;
  font: $f-nm-16-po;
  background: #e8e8e8 0% 0% no-repeat padding-box;
}
.error-marker {
  position: absolute;
  background-color: rgb(236, 149, 149);
}
.error-marker2 {
  position: absolute;
  background-color: rgb(250, 252, 120);
}
.font {
  font: $f-900-1719-av;
}
.info-font {
  font: $f-nm-14-po;
}
.action-log {
  height: 50%;
  position: absolute;
  z-index: 2147483635;
  bottom: 0px;
  right: 0px;
  width: calc(100% - 22rem);
}
.action-log-btn {
  color: #51597E;
}
