@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.cancel-btn {
  border: 1px solid #505981;
  border-radius: 2px;
  color: #505981;
}

.confirm-btn {
  background: #505981 0% 0% no-repeat padding-box;
  border-radius: 2px;
  color: #fff;
}
.modal-content-text {
  font: $f-md-1717-av;
  color: #222222;
}
.new-cancel {
  font: $f-900-1520-av;
  color: #223067;
  text-transform: uppercase;
  width: 133px;
  height: 47px;
  border: 1px solid #223067;
  border-radius: 2px;
  padding-top: 12px;
  &:hover {
    border-color: #152255;
  }
}
.new-confirm {
  width: 144px;
  height: 47px;
  background: linear-gradient(140deg, #7d90e5 -10%, #152255 80%);
  border-radius: 2px;
  font: $f-900-1520-av;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  padding-top: 12px;
  &:hover {
    background: linear-gradient(140deg, #7d90e5 -20%, #152255 60%);
    border-radius: 2px;
    color: #fff;
  }
}

.login-contact-us-link {
  text-decoration: none;
  text-align: left;
  font: $f-md-1719-po;
  letter-spacing: 0px;
  color: #284bed;
  opacity: 1;
}

.login-dont-have-acc-text {
  text-align: left;
  font: $f-md-1719-po;
  letter-spacing: 0px;
  color: #34363b;
  opacity: 1;
}

.sign-in-text {
  font: $f-md-3539-po;
  letter-spacing: 0px;
  color: #34363b;
  opacity: 1;
}

.ms-login-btn {
  border: 1px solid #e5e5e5;
  border-radius: 11px;
  opacity: 1;
  padding: 3%;
  font: $f-md-1726-po;
  text-align: left;
  letter-spacing: 0px;
  color: #34363b;
}

.btn-login {
  background: #5772ef 0% 0% no-repeat padding-box;
  border-radius: 11px;
  opacity: 1;
  padding: 4%;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font: $f-md-1719-po;
}

.inp-placeholder-clr-login {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e5e5e5;
  border-radius: 11px;
  opacity: 1;
  padding: 4%;
  font: $f-md-1719-po;
}

.inp-placeholder-clr-login::placeholder {
  font: $f-md-1719-po;
  color: #a7abaf;
  text-align: left;
  letter-spacing: 0px;
}
.left-window {
  position: relative;
}

.left-panel-text {
  font: $f-md-2039-po;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  position: fixed;
  bottom: 74vh;
  left: 5vw;
  width: 45% !important;
}

.carousel-panel {
  bottom: 0;
  position: absolute;
  right: 0%;
}

.carousel-indicators1 {
  bottom: 62vh;
  position: absolute;
  min-width: 3rem;
  left: -31vw !important;
  margin-left: 12%;
}

.carousel-indicators button.active {
  width: 30px !important;
  height: 2px;
  border-radius: 999px;
  opacity: 1;
  border: 5px solid white !important;
}

.carousel-indicators button {
  width: 5px !important;
  height: 2px;
  border-radius: 50%;
  opacity: 1 !important;
  border: 5px solid white !important;
}

.password-container {
  position: relative;
}

.forgot-password-link {
  position: absolute;
  bottom: 22%;
  right: 5%;
  font: $f-md-1719-po;
  text-align: left;
  letter-spacing: 0px;
  color: #374791;
  opacity: 1;
  text-decoration: none;
}

.input-label {
  text-align: left;
  font: $f-md-1719-po;
  letter-spacing: 0px;
  color: #34363b;
  opacity: 1;
}

.or-text {
  text-align: left;
  font: $f-nm-1625-po;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.hr-line {
  opacity: 1;
  border: 1px solid #cac9cb;
}

.loginPage-form {
  min-width: 70%;
}

body,
html {
  margin: 0;
  overflow: hidden;
  height: 100%;
}

.login-right-side {
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
}
.slider-images {
  width: 100%;
  height: auto;
}
