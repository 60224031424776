@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.hover-text-color {
  color: #000 !important;
  &.active {
    color: $white-smoke-white-clr !important;
    background-color: $dark-black-clr;
  }
  &:hover {
    color: $portage-clr !important;
  }
}

.integration-input {
  background: $white-clr 0% 0% no-repeat padding-box;
  border: 1px solid $dim-gray-border-clr;
  height: 42px;
}

.integration-input,
.integration-webhook-url-inp,
.integration-agent-num {
  color: $oxford-blue-clr-2;
  font: $f-md-1722-av;
}

.integration-input::placeholder {
  color: $waikawa-grey-blue-clr;
  font: $f-md-1722-av;
}

.integration-inp-subtitle {
  color: $oxford-blue-clr;
  font: $f-md-1722-av;
}

.integration-subtitle {
  color: $suva-grey-grey-clr-3;
  font: $f-900-2031-av;
  margin-top: 0.6rem;
}
.mt-225 {
  margin-top: 2.25rem;
}

.pt-065rem {
  padding-top: 0.65rem;
}

.integration-main-title {
  color: $black-clr-2;
  font: $f-900-2531-av;
}

.integration-inp-label {
  color: $tangaroa-clr;
  font: $f-md-1719-av;
}

.integration-webhook-url-inp {
  background: transparent;
  padding: 0 0.5rem;
  width: calc(100% - 2.3rem);
}

.integration-webhook-container {
  background: $eclipse-grey-clr-3 0% 0% no-repeat padding-box;
  border: 1px solid $dim-gray-border-clr;
  padding: 0.2rem 0;
}
