@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.horizontal-scroll-container {
  align-items: center;
  display: flex;
  flex-grow: 0.6;
  list-style: none;
  margin: 0;
  overflow: auto;
  padding: 0;
  white-space: nowrap;
}

.horizontal-scroll-container::-webkit-scrollbar {
  display: none;
}

.horizontal-scroll-container li {
  color: $tangaroa-clr;
  cursor: pointer;
  display: inline-block;
  font: $f-md-1519-av;
  height: 23px;
  margin: 0 4%;
}

.horizontal-scroll-selected-item {
  border-bottom: 2px solid $midnight-express-blue-clr;
  // padding-bottom: 3.5%;
}

.hzs-channel-icons-container {
  display: flex;
  justify-content: space-between;
  margin: 0 1rem;
  overflow: auto;
  white-space: nowrap;
}

.hzs-channel-icon-container {
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 6.7rem;
  justify-content: space-between;
  padding: 0.5rem 1rem 0 1rem;
  // width: 8rem;
  margin: 0 0.1rem;
  // white-space: pre-wrap;
}

.hzs-channel-icon-selected {
  background: $white-clr 0% 0% no-repeat padding-box;
  border: 1px solid $waikawa-grey-blue-clr;
}

.channel-horizontal-container {
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0;
  overflow: auto;
  padding: 0;
  width: 95%;
  white-space: nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
}
.channel-horizontal-container li {
  color: $tangaroa-clr;
  cursor: pointer;
  display: inline-block;
  font: $f-nm-1726-Po;
  margin: 0 2.5%;
}

.channel-dropdown {
  background: #25293d 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 5px;
  width: 8rem;
  font: $f-md-1726-Po;
}
.selected-channel-nav {
  background: #7983b4;
}
