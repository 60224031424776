@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.graph > header {
  background: $mortar-grey-clr 0% 0% no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
  color: $white-clr;
  opacity: 0.91;
  padding: 0.25rem 1rem;
  width: 10rem;
}

.graph > header > span {
  color: $white-clr;
  font-family: Avenir;
}

.graph > footer {
  align-items: center;
  background: $wild-blue-yonder-blue-clr 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 3px 6px $black-clr;
  color: $white-clr;
  display: flex;
  opacity: 0.91;
  padding: 0.5rem 1rem;
  width: 10rem;
}

.graph > footer > span {
  padding: 0.25rem 0;
}
