$alice-blue-clr: #dbf0ff;
$bg-smoke-clr: #f5f6f7;
$bg-white-clr-2: #fafafa;
$bg-white-clr: #f1f1f1;
$black-border-clr: #0000001d;
$black-box-shadow-clr: #0000000d;
$black-clr-2: #000000be;
$black-clr: #00000029;
$black-pearl-blue-clr: #09090d;
$black-table-th-clr: #000000c3;
$blue-zodiac-blue-clr: #3b4058;
$box-shadow-black-clr: #0000000b;
$box-shadow-grey-clr: #818181af;
$bright-grey-clr: #5f5f60;
$chambray-clr: #505981;
$charcoal-grey-clr: #404040c3;
$charcoal-grey-clr-2: #4040409d;
$charcoal-grey-clr: #414141;
$cornflower-blue-clr: #9498ef;
$danger-clr: #cb4e4e;
$dark-black-clr: #00000058;
$dark-gray-grey-clr: #acacac;
$denim-clr: #1151b5;
$dim-gray-border-clr-2: #7070702b;
$dim-gray-border-clr: #7070700d;
$dim-gray-border-clr: #70707026;
$dim-gray-grey-clr-2: #7070701a;
$dim-gray-grey-clr: #70707019;
$dim-gray: #70707034;
$dodge-blue-clr: #227ff7;
$east-bay-blue-clr: #495072;
$echo-blue-clr: #a8adc5;
$eclipse-grey-clr-2: #3b3b3b;
$eclipse-grey-clr: #3d3d3d;
$fire-engine-red-clr: #c61818;
$ghost-white-clr: #e6e9ff80;
$ghost-white-hover-clr: #f2f4ff;
$gondola-grey-clr-2: #323130;
$gondola-grey-clr: #333231;
$grey-clr-2: #7b7b7b;
$grey-clr: #868686;
$jacksons-purple-border-clr: #3c4980;
$jacksons-purple-violet-clr: #3d4981;
$light-grey-clr: #d3d3d3;
$midnight-express-blue-border-clr: #25293e;
$midnight-express-blue-clr: #25293d;
$midnight-express-clr: #25293d4d;
$mine-shaft-blue-clr: #383d41b2;
$moody-blue-violet-clr: #8677c4;
$mortar-grey-clr: #565252;
$neon-blue-clr: #5468f7;
$nero-clr: #222222;
$nero-grey-border-clr: #2222221c;
$nero-grey-clr: #1d1d1d;
$night-rider-clr: #333333;
$persian-blue-clr: #2345d9;
$petite-orchid-clr: #d88e8e;
$portage-clr: #8f9ddb;
$pure-black-clr: #000000;
$santas-grey-blue-clr: #999aa1;
$secondary-dim-gray: #707070;
$silver-grey: #bcbcbc;
$slate-grey-clr: #6b7394;
$solitude-blue-clr: #e5e6ec;
$suva-grey-clr-2: #909090ac;
$suva-grey-grey-clr-2: #8e8e8e;
$suva-grey-grey-clr-3: #898989;
$suva-grey-grey: #959595;
$tangaroa-clr: #162128;
$ultramarine-clr: #51597e;
$valencia-red-clr: #d35555;
$waikawa-grey-blue-bg-clr: #5e668d34;
$waikawa-grey-blue-clr: #5e668d77;
$white-clr: #ffffff;
$white-smoke-clr: #f8f8f8;
$white-smoke-white-clr-2: #ededed;
$white-smoke-white-clr-3: #f2f2f2;
$white-smoke-white-clr: #f5f5f5;
$wild-blue-yonder-blue-clr: #7983b4;
$oxford-blue-clr: #252f3681;
$oxford-blue-clr-2: #252f36;
$eclipse-grey-clr-3: #3b3b3b0b;
$blue-hover: #E5E9FC;



//#7a8fef 1ST PANEL
//#e3e3e3 2ND PANEL INPUTS
// #b9b9b9 2ND PANEL INPUTS